import { Stack, CircularProgress } from "@mui/material";
import { GetLocationListDocument, RelationAddress } from "@cbex/data-access";
import { CustomAutoComplete, CustomTextField } from "@cbex/ui/input";
import { CustomTypographyComponent } from "@cbex/ui/text";
import { useTranslations } from "next-intl";
import React from "react";
import { useQuery } from "@apollo/client";

interface ExamAddressesDropDownProps {
  controlDisabled: boolean;
  inputParam: string;
  label?: string;
  required?: boolean;
  width?: number | string;
  onValueChanged: (val: string) => void;
  orgID?: string;
  addresType: string;
}

export const ExamAddressesAutoComplete = (
  props: ExamAddressesDropDownProps
) => {
  const {
    controlDisabled,
    inputParam,
    onValueChanged,

    width,
    label,
    required,
  } = props;
  const t = useTranslations();
  const { data: addressTypeData, loading: addressTypeDataLoading } = useQuery(
    GetLocationListDocument,
    {}
  );
  const [open, setOpen] = React.useState(false);
  const [inputValue, setInputValue] = React.useState("");
  const [addressType, setAddressType] = React.useState<any>({
    id: "",
    name: "",
    city: "",
    street: "",
    postalCode: "",
    number: "",
  });

  const [addressTypeList, setAddressTypeList] = React.useState<
    RelationAddress[]
  >([]);

  React.useEffect(() => {
    if (
      addressTypeData &&
      addressTypeData.LocationList &&
      addressTypeData.LocationList.length > 0
    ) {
      //@ts-ignore
      const newAddressTypes = addressTypeData.LocationList.map(
        (address: RelationAddress) => ({
          //@ts-ignore
          name: address.name,
          id: address.id,
          city: address.city,
          street: address.street,
          postalCode: address.postalcode,
          number: address.number,
        })
      );
      setAddressTypeList([...newAddressTypes]);
    } else {
      setAddressTypeList([]);
    }
  }, [addressTypeData]);

  React.useEffect(() => {
    if (inputParam && addressTypeList) {
      const foundAddress = addressTypeList.find(
        (address: RelationAddress) => address.id === inputParam
      );

      if (foundAddress) {
        setAddressType({
          name: foundAddress.name,
          id: foundAddress.id,
          city: foundAddress.city,
          street: foundAddress.street,
          //@ts-ignore
          postalCode: foundAddress.postalCode,
          number: foundAddress.number,
        });
      }
    }
  }, [inputParam, addressTypeList]);

  const handleAddressTypeChange = (value: any) => {
    setAddressType(value);
    if (onValueChanged && value) {
      onValueChanged(value.id);
    } else {
      onValueChanged(undefined);
    }
  };

  return (
    <Stack id={"OrganizationAddressType"}>
      {addressTypeList && addressTypeList.length > 0 && (
        <CustomAutoComplete
          id="asynchronous-OrganizationAddressType"
          sx={{ width: width ? width : 300 }}
          open={open}
          size="small"
          disabled={controlDisabled}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          // @ts-ignore
          onChange={(event: any, newValue: string | null) =>
            handleAddressTypeChange(newValue)
          }
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          // @ts-ignore
          isOptionEqualToValue={(option, val) => option.id === val.id}
          // @ts-ignore
          getOptionLabel={(option) =>
            // @ts-ignore
            option.name
              ? // @ts-ignore
                option.name + " " + option.city
              : // @ts-ignore
                option.street + " " + option.city
          }
          // @ts-ignore
          renderOption={(propsVal: any, option: any) => (
            <li {...propsVal} key={option.id}>
              <Stack width={"100%"}>
                <CustomTypographyComponent>
                  {option.name}
                </CustomTypographyComponent>
                <CustomTypographyComponent>
                  {option.street} {option.number} {option.postalcode}
                  {option.city}
                </CustomTypographyComponent>
              </Stack>
            </li>
          )}
          value={addressType}
          // @ts-ignore
          options={addressTypeList}
          loading={addressTypeDataLoading}
          renderInput={(params) => (
            <CustomTextField
              {...params}
              variant="filled"
              size={"small"}
              required={required}
              controldisabled={controlDisabled}
              label={label ? label : t("selectLocation")}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {addressTypeDataLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      )}
    </Stack>
  );
};

export default ExamAddressesAutoComplete;
