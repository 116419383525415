import React from "react";
import { Button, Stack, Box } from "@mui/material";
import { useTranslations } from "next-intl";
import { CustomTypographyComponent } from "@cbex/ui/text";
import { ForgotPasswordForm, ForgotPasswordFormState } from "../index";
import { useTheme } from "@cbex/utils/theme";

import axios from "axios";

export const ForgotPasswordComposite = () => {
  const t = useTranslations("login");
  const forgotRef = React.useRef();
  const theme = useTheme();
  const [failedReset, setFailedReset] = React.useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = React.useState<boolean>(false);

  const handleForgotDetails = (val: ForgotPasswordFormState) => {
    if (val) {
      axios
        .post(
          "auth/forgotPassword",
          { EmailAddress: val.userName },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            withCredentials: true,
          }
        )
        .then((res: any) => {
          setIsSubmitted(true);
          if (res && res.data && res.data.Status === 200) {
            setFailedReset(false);
          } else {
            setFailedReset(true);
          }
        })
        .catch((FEerror: any) => console.log("FE error", FEerror));
    }
  };

  const handleForgotClicked = () => {
    if (forgotRef && forgotRef.current) {
      //@ts-ignore
      forgotRef.current.onSubmit();
    }
  };

  return (
    <Stack
      alignItems="center"
      height="500px"
      width="500px"
      id="ForgotPasswordComposite"
    >
      <Stack mt={3} height={"20%"} mb={6}>
        <Box
          component="img"
          sx={{
            height: 80,
          }}
          //@ts-ignore
          alt="Cbex logo"
          src="https://storage.googleapis.com/cbex/cbex_groen_ondertitel_RGB.png"
        />
      </Stack>
      <Stack
        spacing={2}
        mt={2}
        justifyContent={"center"}
        alignItems="center"
        width="100%"
      >
        <CustomTypographyComponent variant="h5">
          {t("forgotPassword")}
        </CustomTypographyComponent>
        <CustomTypographyComponent>
          {t("fillDetailsForgotPassword")}
        </CustomTypographyComponent>
      </Stack>

      {isSubmitted && failedReset && (
        <CustomTypographyComponent
          variant="body1"
          color={theme.appColors.error}
        >
          {t("failedPaswordReset")}
        </CustomTypographyComponent>
      )}
      {isSubmitted && !failedReset && (
        <CustomTypographyComponent
          variant="body1"
          color={theme.palette.primary.main}
        >
          {t("succeededPaswordReset")}
        </CustomTypographyComponent>
      )}
      <Stack justifyContent={"center"} alignItems="center" width="100%">
        <ForgotPasswordForm
          onForgotPasswordDetails={handleForgotDetails}
          ref={forgotRef}
        />
      </Stack>

      <Stack>
        <Stack alignItems={"center"} width="100%">
          <Button
            color="primary"
            variant="contained"
            onClick={handleForgotClicked}
            sx={{ mr: 1, width: "90%" }}
          >
            {t("resetPassword")}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ForgotPasswordComposite;
