import { IconButton, Stack } from "@mui/material";
import React from "react";
import {
  ExamDetailsDefaultValues,
  getExamDetailsDefaultValues,
} from "../utils/ExamDetailsDefaultValues";
import {
  CbexMomentExam,
  GetActivityTypeDocument,
  PlanningActivityType,
  ResourceListByActivityTypeIdDocument,
  ResourcesByActivityTypeIdAndEmployeeIdRow,
  StoreCandidateParams,
  StoreCandidatesDocument,
  UpdateExamDocument,
} from "@cbex/data-access";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslations } from "next-intl";
import SaveIcon from "@mui/icons-material/Save";
import { CustomTab, TabPanel } from "@cbex/ui/tabs";
import ExamDetailsForm from "../forms/ExamDetailsForm";
import CandidateDetailsFormNew from "../forms/CandidateDetailsFormNew";
import { useMutation, useQuery } from "@apollo/client";
import _ from "lodash";
import { removeInArray } from "../utils/examFormUtils";
import { SnackBar } from "@cbex/ui/snack-bar";
import { getExamEditState } from "../utils/getExamEditState";

interface ExamDetailsCompositeProps {
  examData: CbexMomentExam;
  refetchOverviewData: () => void;
}

const ExamDetailsComposite = ({
  examData,
  refetchOverviewData,
}: ExamDetailsCompositeProps) => {
  const t = useTranslations();
  const [tabValue, setTabValue] = React.useState(0);
  const [snackBar, setSnackBar] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("");
  const [resources, setResources] = React.useState<
    ResourcesByActivityTypeIdAndEmployeeIdRow[]
  >([]);
  const [planningActivityTypes, setPlanningActivityTypes] = React.useState<
    PlanningActivityType[]
  >([]);
  // left hand side  and also part of the search
  const [localPlanningActivityTypes, setLocalPlanningActivityTypes] =
    React.useState<PlanningActivityType[]>([]);
  // right hand side for dispalying
  const [selectedActivityTypes, setSelectedActivityTypes] = React.useState<
    PlanningActivityType[]
    //@ts-ignore
  >([]);
  const isEditable = getExamEditState(examData);

  const onUpdateMutationComplete = () => {
    setTimeout(() => {
      setSnackBarMessage(t("feedback.updateSuccess"));
      setSnackBar(true);
      setSeverity("success");
      refetchOverviewData();
    }, 1000);
  };
  const [updateExam] = useMutation(UpdateExamDocument, {
    onCompleted: onUpdateMutationComplete,
  });

  const handleOnStoreMutationCompleted = function () {
    setTimeout(() => {
      setSnackBarMessage(t("feedback.updateSuccess"));
      setSnackBar(true);
      setSeverity("success");
    }, 1000);
    setTimeout(() => {
      refetchOverviewData();
    }, 1000);
  };
  const [storeCandidates] = useMutation(StoreCandidatesDocument, {
    onCompleted: handleOnStoreMutationCompleted,
  });

  const handleTabValue = (val: number) => {
    setTabValue(val);
  };

  const examSchema = yup.object().shape({
    startDate: yup.date().required(t("labels.required")),
    locationId: yup.string().required(t("labels.required")),
    activityTypeIDs: yup.array().min(1, t("labels.required")),
    resources: yup.array().test(
      "activityTypeIDs",
      t("labels.required"),

      (val, context) => {
        //@ts-ignore
        const { activityTypeIDs } = context.parent;
        if (
          activityTypeIDs[0].settings.allowSelectResource.toString() === "true"
        ) {
          if (val.length === 0) {
            return context.createError({ message: t("labels.required") });
          } else {
            return true;
          }
        } else {
          return true;
        }
      }
    ),
    candidates: yup.array(
      yup.object({
        lastName: yup.string().required(t("labels.required")),
        initials: yup.string().required(t("labels.required")),
        dateOfBirth: yup.date().required(t("labels.required")),
        emailAddress: yup
          .string()
          .email(t("labels.invalidEmail"))
          .notRequired(),

        activityTypeID: yup.string().required(t("labels.required")),
      })
    ),
  });

  const form = useForm<ExamDetailsDefaultValues>({
    mode: "onSubmit",
    defaultValues: getExamDetailsDefaultValues(examData),
    //@ts-ignore
    resolver: yupResolver(examSchema),
  });

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackBar(false);
  };

  const { getValues, handleSubmit, control } = form;

  const activityTypeChanges = useWatch({
    control: control,
    name: "activityTypeIDs",
  });

  const { data: activityTypeData, loading: activityTypeDataLoading } = useQuery(
    GetActivityTypeDocument,
    {
      variables: {},
      fetchPolicy: "network-only",
    }
  );

  const { data: resourcesData, loading: resourcesLoading } = useQuery(
    ResourceListByActivityTypeIdDocument,
    {
      variables: {
        activityTypeID:
          (getValues("activityTypeIDs") &&
            //@ts-ignore
            getValues("activityTypeIDs").map((item) => item.activityTypeID)) ||
          [],
      },
      fetchPolicy: "network-only",
      skip:
        !getValues("activityTypeIDs") ||
        getValues("activityTypeIDs").length === 0,
    }
  );

  React.useEffect(() => {
    if (
      !activityTypeDataLoading &&
      activityTypeData &&
      activityTypeData.ActivityTypes &&
      activityTypeData.ActivityTypes.length > 0
    ) {
      const newActivityType = activityTypeData.ActivityTypes.map(
        (item: PlanningActivityType) => ({
          activityTypeID: item.activityTypeID,
          name: item.name,
          competenceID: item.competenceID,
          settings: item.settings,
        })
      );
      setPlanningActivityTypes([...newActivityType]);
      setLocalPlanningActivityTypes(
        removeInArray(
          [...newActivityType],
          //@ts-ignore
          getValues("activityTypeIDs")
        )
      );
    } else {
      setPlanningActivityTypes([]);
      setLocalPlanningActivityTypes([]);
    }
  }, [activityTypeData, activityTypeDataLoading, getValues]);

  React.useEffect(() => {
    if (
      resourcesData &&
      resourcesData.ResourceListByActivityTypeID &&
      resourcesData.ResourceListByActivityTypeID.length > 0
    ) {
      const resourceList: ResourcesByActivityTypeIdAndEmployeeIdRow[] =
        resourcesData.ResourceListByActivityTypeID.map(
          //@ts-ignore
          (resourceInput: ResourcesByActivityTypeIdRow) => ({
            employeeID: resourceInput.employeeID,
            name: resourceInput.name,
            resourceID: resourceInput.resourceID,
          })
        );

      setResources(resourceList);
    } else {
      setResources([]);
    }
  }, [resourcesData, resourcesLoading]);

  React.useEffect(() => {
    if (activityTypeChanges) {
      //@ts-ignore
      setSelectedActivityTypes(activityTypeChanges);
    }
  }, [activityTypeChanges]);

  const candidateDetailsPanel = React.useMemo(
    () => (
      <TabPanel value={tabValue} index={1}>
        <CandidateDetailsFormNew editAllowed={isEditable} form={form} />
      </TabPanel>
    ),
    [form, isEditable, tabValue]
  );

  const handleOnError = (errors: any) => {
    if (errors) {
      setSnackBar(true);
      setSeverity("error");
      setSnackBarMessage(t("feedback.missingValues"));
    }
  };

  const handleExamUpdated = (examDetails: ExamDetailsDefaultValues) => {
    if (!_.isEmpty(form.formState.errors)) {
      setSnackBarMessage(t("feedback.missingValues"));
      setSnackBar(true);
      setSeverity("error");
      return;
    }

    if (
      examDetails.locationId === undefined ||
      examDetails.locationId === "" ||
      examDetails.startDate === undefined ||
      examDetails.startDate === null
    ) {
      setSnackBarMessage(t("feedback.missingValues"));
      setSnackBar(true);
      setSeverity("error");
      return;
    } else if (
      examDetails &&
      examDetails.activityTypeIDs &&
      examDetails.activityTypeIDs.length > 0
    ) {
      updateExam({
        variables: {
          input: {
            notes: examDetails.notes,
            momentID: examDetails.momentID,
            //@ts-ignore
            activityTypeIDs: examDetails.activityTypeIDs.map(
              //@ts-ignore
              (act) => (act.activityTypeID && act.activityTypeID) || act
            ),
            startDate: examDetails.startDate,
            //@ts-ignore
            locationId: examDetails.locationId,
            resources:
              examDetails.resources && examDetails.resources.length > 0
                ? examDetails.resources.map(
                    //@ts-ignore
                    (res) => (res.resourceID && res.resourceID) || res
                  )
                : null,
          },
        },
      }).then(() => {
        const mappedCandidates: StoreCandidateParams[] = [];
        examDetails.candidates.forEach((element) => {
          mappedCandidates.push({
            activityTypeID: element.activityTypeID,
            candidateID: (element.candidateID && element.candidateID) || "0",
            dateOfBirth: element.dateOfBirth,
            lastName: element.lastName,
            emailAddress: (element.emailAddress && element.emailAddress) || "",
            infix: element.infix,
            initials: element.initials,
            notes: element.notes,
          });
        });

        storeCandidates({
          variables: {
            input: mappedCandidates,
            momentID: examData.moment.momentID,
          },
        }).catch(() => {
          setTimeout(() => {
            setSnackBarMessage(t("feedback.storeFailed"));
            setSnackBar(true);

            setSeverity("error");
          }, 1000);
        });
      });
    } else {
      setSnackBarMessage(t("feedback.missingValues"));
      setSnackBar(true);
      setSeverity("error");
    }
  };

  return (
    <Stack id="ExamDetailsComposite">
      <Stack alignItems={"flex-end"}>
        <IconButton
          sx={{ width: 15 }}
          disabled={!isEditable}
          type="submit"
          onClick={handleSubmit(
            () => handleExamUpdated(getValues()),
            (err: any) => handleOnError(err)
          )}
        >
          <SaveIcon color={isEditable ? "primary" : "disabled"} />
        </IconButton>
      </Stack>

      <Stack>
        <CustomTab
          id="ExamTabs"
          onValueChanged={handleTabValue}
          tabPanels={[t("labels.examDetails"), t("labels.candidateDetails")]}
        ></CustomTab>

        <form>
          <TabPanel value={tabValue} index={0}>
            <ExamDetailsForm
              allActivities={planningActivityTypes}
              editAllowed={isEditable}
              allResources={resources}
              locationDetails={examData.moment && examData.moment.location}
              currentSelectedActivity={selectedActivityTypes}
              filteredActivities={localPlanningActivityTypes}
              form={form}
            />
          </TabPanel>
          {candidateDetailsPanel}
        </form>
      </Stack>
      {snackBar && (
        <SnackBar
          openState={snackBar}
          //@ts-ignore
          severity={severity}
          message={snackBarMessage}
          //@ts-ignore
          onClose={handleClose}
        ></SnackBar>
      )}
    </Stack>
  );
};

export default ExamDetailsComposite;
