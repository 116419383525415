import {
  NewExamDetailParams,
  CbexMomentExam,
  PlanningActivity,
  ResourcesByActivityTypeIdAndEmployeeIdRow,
  PlanningActivityType,
  UpdateExamDetailParams,
  CbexPortalCandidatesByMomentIdRow,
} from "@cbex/data-access";
export type NewExamDetailsDefaultValues = NewExamDetailParams & {
  activityTypes: PlanningActivityType[];
  planningResources: ResourcesByActivityTypeIdAndEmployeeIdRow[];
};

export type ExamDetailsDefaultValues = UpdateExamDetailParams & {
  candidates: CbexPortalCandidatesByMomentIdRow[];
};

export const getNewExamDetailsDefaultValues = (input?: NewExamDetailParams) => {
  const defaults: NewExamDetailParams = {
    activityTypeIDs: (input && input.activityTypeIDs) || [],
    startDate: input?.startDate || undefined,
    endDate: input?.endDate || undefined,
    locationId: input?.locationId || "",
    notes: input?.notes || "",
    resources: input?.resources || [],
  };
  return defaults;
};

export const getExamDetailsDefaultValues = (input: CbexMomentExam) => {
  const defaults: ExamDetailsDefaultValues = {
    //@ts-ignore
    activityTypeIDs:
      (input &&
        input.activities &&
        input.activities.length > 0 &&
        input.activities.map((act: PlanningActivity) => act.activityType)) ||
      [],
    locationId:
      (input &&
        input.moment &&
        input.moment.locationIds &&
        input.moment.locationIds.length > 0 &&
        input.moment.locationIds[0]) ||
      "",
    momentID: input && input.moment && input.moment.momentID,
    startDate: input && input.moment && input.moment.startDate,
    notes: input && input.moment && input.moment.notes,
    endDate: input && input.moment && input.moment.endDate,
    //@ts-ignore
    candidates: input && input.candidates !== undefined ? input.candidates : [],
    resources: (input && input.moment && input.moment.resources) || [],
  };

  return defaults;
};
